<template>
  <footer class="bg-dark text-white">
    <div class="container-xxl px-3_5 px-md-7 pt-5 py-3 d-flex flex-column flex-column-reverse justify-content-between flex-md-row">
      <div class="d-flex flex-column align-items-center">
        <div class="logo">
          <img src="/images/footer/logo-white.svg">
        </div>
        <p class="text-center mt-3 mt-md-4">{{ $t("copyRight") }}</p>
      </div>

      <div class="d-flex flex-column align-items-center">
        <div class="icon-links row">
          <div class="col-3 col-md text-center mb-3  mb-md-0" v-for="(iconLink, index) in iconLinks" :ref="iconLink.type" :key="index"
               @click="openLink(iconLink)">
            <img v-if="iconLink.type != 'wx'" :src="iconLink.icon" :alt="iconLink.name">
            <el-popover
                v-else
                placement="top"
                :width="280"
                trigger="click"
                content="this is content, this is content, this is content"
            >
              <template #reference>
                <img :src="iconLink.icon" :alt="iconLink.name">
              </template>
              <img class="w-100" :src="Store.state.lang == 1?'/images/about/wechart_cn.png':'/images/about/wechart_en.jpg'" >
            </el-popover>
          </div>
        </div>
        <p class="text-center mt-3 mt-md-4">{{ $t("footerDes") }}</p>

      </div>
    </div>
  </footer>
</template>

<script setup>
import Store from "@/store/index.js";

const iconLinks = [
  {
    icon: "/images/footer/icon-1.svg",
    name: "substack",
    urlEn: "https://bit.ly/TaxDAO-EN",
    urlCn: "https://bit.ly/TaxDAO"
  },
  {
    icon: "/images/footer/icon-2.svg",
    name: "领英",
    urlEn: "https://www.linkedin.com/company/fintaxdc/",
    urlCn: "https://www.linkedin.com/company/fintaxdc/"
  },
  {
    icon: "/images/footer/icon-3.svg",
    name: "频道",
    urlEn: "//t.me/TaxDAOEN",
    urlCn: "//t.me/TaxDAODC"
  },
  {
    icon: "/images/footer/icon-4.svg",
    name: "社群",
    urlEn: "http://t.me/TaxDAO_ENchat",
    urlCn: "http://t.me/TaxDAOinfo"
  },
  {
    icon: "/images/footer/icon-5.svg",
    name: "推特",
    urlEn: "https://x.com/TaxDAO_DC",
    urlCn: "https://x.com/FinTax_CN"
  },
  {
    icon: "/images/footer/icon-6.svg",
    name: "微信",
    type: "wx",
  },
  {
    icon: "/images/footer/icon-7.svg",
    name: "邮箱",
    type: "email",
    url: "contact@fintax.tech"
  }
]

function openLink(item) {
  if (item.type === "email") {
    window.open(`mailto:${item.url}`, "_blank");
  } else if (item.type !== "wx") {
    window.open(Store.state.lang == 1 ? item.urlCn : item.urlEn, "_blank");
  }
}
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  footer {
    .logo {
      height: 1.85*1.2rem !important;
      display: flex;
      align-items: center;
       img {
         height: 1.75*1.2rem;
       }
    }

    .icon-links img {
      width: 3.125*1.2rem !important;
      height: 1.85*1.2rem !important;
    }
  }
}
footer {
  font-size: 0.625rem;

  .logo {
    height: 1.75*0.8rem;
    img {
      height: 100%;
    }
  }

  .icon-links img {
    width: 3.125*0.8rem;
    height: 1.85*0.8rem;
    cursor: pointer;
  }
}
</style>
