import { createI18n } from "vue-i18n";     // 引入vue-i18n组件
import zh from "./lang/zh";     // 引入zh.js 模块
import en from "./lang/en";     // 引入en.js 模块


export const getCurrLang = () => {
    let localStorageLang = localStorage.getItem("localeKey");// 本地存储语言
    if(!['1', '2'].includes(localStorageLang)) {
        localStorageLang = '2';
    }
    return localStorageLang;
}

//注册i18n实例并引入语言文件
const i18n = createI18n({
    legacy: false,
    locale: getCurrLang(), // 语言标识
    fallbackLocale: "2", //没有英文的时候默认中文语言
    messages: {
        1:zh,
        2:en,
    },
});
export default i18n;

