<template>
  <div class="main-page d-flex flex-column w-100 h-100 bg-white">
    <PageHeader />
    <div class="main-layout">
      <el-scrollbar view-style="overflow-x: hidden;">
        <router-view />
        <PageFooter />
      </el-scrollbar>
    </div>
  </div>
</template>
<script setup>
import PageHeader from "@/components/page-header.vue";
import PageFooter from "@/components/page-footer.vue";
import Store from '@/store';

Store.dispatch("initBaseInfo");
</script>

<style lang="scss">
.main-page {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;

  .main-layout {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .el-scrollbar .el-scrollbar__view > div{
      padding-top: 4.5rem;
      min-height: calc(100vh - 9.6875rem);
    }
  }
}
</style>
