<template>
  <header>
    <nav class="navbar navbar-expand-md fixed-top p-0 bg-dark">
      <div class="container-xxl px-3_5 px-md-7">
        <a class="navbar-brand d-flex align-items-center" href="#">
          <img alt="Logo" class="logo-icon" src="/images/footer/logo-white.svg">
        </a>

        <button class="navbar-toggler border-0 p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <img alt="Logo" class="logo-icon" src="~@/assets/header/meun-icon.png">
        </button>

        <div class="collapse navbar-collapse" id="navbarCollapse" ref="navbarCollapse">
          <ul class="navbar-nav justify-content-center flex-grow-1">
            <li v-for="item in meuns" :key="item.name" :class="{'dropdown':item.children}"
                class="nav-item"
            >
              <a v-if="!item.children" aria-current="page" class="nav-link px-md-1 px-lg-4 mx-md-2" :class="item.meun === activeMeun?'text-warning':'text-white'" :href="`./#${item.link}`">{{ $t(item.name) }}</a>
              <a v-if="item.children" aria-expanded="false" class="nav-link dropdown-toggle px-md-1 px-lg-4 mx-md-2"
                 data-bs-toggle="dropdown"
                 href="#"
                 role="button">
                {{ $t(item.name) }}
              </a>
              <ul v-if="item.children" class="dropdown-menu">
                <li v-for="child in item.children" :key="child.name" class="nav-item px-2 lh-lg">
                  <a class="nav-link" href="#" >
                    {{
                      $t(child.name)
                    }}
                  </a>
                </li>
              </ul>
            </li>

            <li class="nav-item dropdown d-md-none d-inline">
              <a
                aria-expanded="false"
                class="nav-link dropdown-toggle d-flex justify-content-left align-items-center text-white"
                data-bs-toggle="dropdown"
                href="#"
                role="button"
              >
                <img class="me-1" src="~@/assets/header/globe.svg">
                {{ lang === "zh" ? "简体中文" : "English" }}
              </a>
              <ul class="dropdown-menu bg-dark">
                <li v-for="item in localArr" :key="item.key" class="px-2 lh-lg text-white" @click="changeLang(item.key)">
                  {{ item.name }}
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div class="lang-box dropdown lang-main d-md-inline d-none">
          <a
            aria-expanded="false"
            class="nav-link dropdown-toggle d-flex justify-content-center align-items-center text-white"
            data-bs-toggle="dropdown"
            href="#"
            role="button"
          >
            <img class="me-1" src="~@/assets/header/globe.svg">
            {{ lang == "1" ? "简体中文" : "English" }}
          </a>
          <ul class="dropdown-menu bg-dark">
            <li v-for="item in localArr" :key="item.key" class="px-2 lh-lg text-white" @click="changeLang(item.key)">
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script setup>
import {ref, getCurrentInstance, reactive, watch, computed} from 'vue'
import Store from "@/store/index.js";

const {proxy} = getCurrentInstance();
const navbarCollapse = ref();
const localArr = [
  {key: "2", name: "English"},
  {key: "1", name: "简体中文"},
];
const lang = ref(Store.state.lang);
const meuns = reactive([
  {
    name: "menu.content",
    link: "/content",
    meun: "content"
  },
  {
    name: "menu.platform",
    link: "/home",
    meun: "home"
  },
  {
    name: "menu.services",
    link: "/services",
    meun: "services"
  },
  {
    name: "menu.about",
    link: "/about",
    meun: "about"
  },
]);
const activeMeun = computed({
  get() {
    return ["articles", "contentAll", "contentOther", "contentHome"].includes(proxy.$route.name)?"content":proxy.$route.name;
  }
});

function changeLang(key) {
  lang.value = key;
  proxy.$i18n.locale = key;
  // 存入缓存
  localStorage.setItem("localeKey", key);
  Store.dispatch("setLang", key);
}

watch(
    () => proxy.$route.query.language,
    (language) => {
      if(language) {
        changeLang(language)
      }
    },
    {immediate: true}
);
watch(
    () => proxy.$route,
    (route, oldRoute) => {
      if(oldRoute && route.name !== oldRoute.name) {
        const hasClass = navbarCollapse.value?.classList.contains('show');
        if(hasClass) {
          navbarCollapse.value?.classList.remove('show');
        }
      }
    },
    {immediate: true}
);
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) and (max-width: 1280px) {
  nav.navbar {
    font-size: 0.8rem !important;
    .logo-icon {
      height: 24px !important;
    }
    .lang-box {
      min-width: 6rem;
      display: flex !important;
      justify-content: end;
      .nav-link {
        img {
          height: 24px !important;
        }
      }
    }
  }
}
@media (min-width: 1280px) {
  nav.navbar {
    .logo-icon {
      height: 28px !important;
    }
    .lang-box {
      min-width: 9.25rem;
      display: flex !important;
      justify-content: end;
    }
  }
  header .navbar-collapse.collapse.show {
    background: transparent !important;
  }
}
header {
  z-index: 100;
  .navbar-collapse.collapse.show {
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
    .navbar-nav {
      padding-left: 1.5rem;
    }
  }
  nav.navbar {
    height: 4.5rem;
    opacity: 0.9;
    .logo-icon {
      height: 20px;
    }
    .navbar-brand {
      height: 4.5rem;
      line-height: 4.5rem;
    }

    .lang-main {
      .dropdown-menu {
        li {
          cursor: pointer;
          &:hover {
            color: var(--bs-warning) !important;
          }
        }
      }
    }

    .navbar-nav {
      padding-left: 1.5rem;
      .nav-item {
        .nav-link {
          font-weight: 500;
          &:hover {
            color: var(--bs-warning) !important;
          }
        }
      }
    }
  }
}
</style>
