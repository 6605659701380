import {createStore} from 'vuex'
import {getBaseInfo} from "@/api/global.js";

let lang = localStorage.getItem("localeKey");
if(!['1', '2'].includes(lang)) {
    lang = '2';
}
export default createStore({
    state: {
        lang: lang,
        baseInfo: {
            substack: "",
            twitter: "",
            telegram: "",
            telegramCode: "",
            telegraphGroup: "",
            mail: "",
            wechat: "",
            ipc: "",
            qrCode: ""
        }
    },
    getters: {
        lang: (state) => state.lang || "1",
        baseInfo: (state) => state.baseInfo,
    },
    mutations: {
        setLang(state, lang = "1") {
            state.lang = lang
        },
        setBaseInfo(state, baseInfo) {
            state.baseInfo = baseInfo
        },
    },
    actions: {
        setLang(store, lang = "1") {
            store.commit("setLang", lang);
        },
        setBaseInfo(store, baseInfo) {
            store.commit("setBaseInfo", baseInfo);
        },
        async initBaseInfo(store) {
            let res = await getBaseInfo();
            store.commit("setBaseInfo", res.result);
        }
    },
    modules: {}
})
