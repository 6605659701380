import axios from 'axios'

const baseURL = "/codebase";
axios.defaults.headers.common["Content-Type"] = "application/json;charset=utf-8";

// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL,
    // 超时
    timeout: 30000,
});

// request拦截器
service.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token") || "";
        if (token.length > 0) {
            // @ts-ignore
            config.headers.common["token"] = token;
        }
        return config;
    },
    (error) => {
        Promise.reject(error).then(r => console.log(r));
    }
);

// 响应拦截器
service.interceptors.response.use(
    (res) => {
        // 未设置状态码则默认成功状态
        const success = res.data.success
        if (success === 200) {
            return res.data;
        } else {
            return res.data;
        }
    },
    (error) => {
        let {message} = error;
        if (message == "Network Error") {
            message = "后端接口连接异常";
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substring(message.length - 3) + "异常";
        }
        return Promise.reject(error);
    }
);

export default service;
