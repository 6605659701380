import request from '@/utils/request.js'

/**
 * 获取基本信息
 * */
export const getBaseInfo = () => {
    return request({
        url: '/work/baseInfo/getBaseInfoRecords',
        method: 'get'
    })
}

/**
 * 获取验证码
 * */
export const getCode = (username, email, language) => {
    return request({
        url: `/work/email/getVerificationCode?username=${username}&email=${email}&language=${language}`
    })
}

/**
 * 提交验证
 * */
export const submitVerify = (username, email, code) => {
    return request({
        url: `/work/email/verifyCode?username=${username}&email=${email}&code=${code}`
    })
}
