export default {
    menu: {
        platform: 'FinTax加密货币税务工具',
        services: '咨询服务',
        content: '内容',
        depth: '深度',
        compile: '编译',
        events: '评论和活动',
        about: '关于我们',
        introduce: 'TAXDAO简介',
        join: '加入我们',
        reprinted: '咨询转载',
        contact: '联系我们',
        all: '全部'
    },
    about: {
        name: "关于我们",
        title: "近年来随着加密货币的合规化，其税务相关内容也成为行业热点。",
        content: "2021年加密货币税务软件TaxBit 完成 1.3 亿美元B轮融资，估值超 13 亿美元，成为独角兽企业。各个国家都相继立法，针对加密货币挖矿、交易等出台相应的税法。中国也在2022年针对一些大户、矿工征税，未来也许会从偶然转为必然。在这样的大背景下，加密货币投资者、机构如何合理配置资产、完善税务合规，也成为了一个热点话题。",
        introduceTitle: "我们希望通过成立 TaxDAO 去帮助社区更好地应对税务合规和税务问题，也希望能通过我们的专业能力，帮助社区走出相对清晰的税务合规路径，从而弥合税务监管和行业之间的 gap，如果在此之上能一定程度推动有关立法的进程，收集信息和需求并发声可能对行业是更加有意义有价值的事情。",
        introduceContent: "我们希望用DAO这种开放平台的形式更多的聚集对于“加密货币行业&税”感兴趣的专业人士和从业者，共同帮助行业理顺合规路径，并且在目前行业税务监管相对早期的阶段进行一些基础研究和建设以帮助行业未来的合规发展。",
    },
    baseInfo: {
        telegram: 'Telegram',
        telegraphGroup: 'TaxDAO Information group',
        mail: '邮箱',
        wechat: '微信',
        copyright: '版权所有',
    },
    titles: {
        more: '查看更多',
        sale: '月销',
        case: '咨询业务案例',
        news: '最新资讯',
        selected: '精选合集',
        pay: '付费阅读',
        recommend: '推荐阅读',
        contact: '可通过以下方式联系我们',
        looKAll: '查看全部',
    },
    articleType: {
        pay: '付费',
        recommend: '推荐',
    },
    articles: {
        date: '发布时间',
        read: '阅读数',
        gather: '合集',
        author: '作者',
        summary: '摘要',
        keyword: '关键词',
        original: '付费查看原文',
        pay: '去支付',
        cancel: '取消',
        username: '用户名',
        captcha: '验证码',
        send: '发送验证码',
        share: '分享',
        search: '请输入你要搜索的文章'
    },
    services: {
        title: "TaxDAO的优势",
        content: "懂得客户需求，服务于企业发展战略：TaxDAO成员均为Web3各细分领域的税务、财务高级管理人员，具有丰富的国际化经验，具备精准识别Web3各细分赛道、企业各生命周期税务需求，立足企业战略目标解决税务问题的能力。Web3领域极致专业的财务、税务团队：TaxDAO团队成员专业互补，整合了加密资产、税务处理与合规监管的丰富实践经验，且具备丰富的国际化经验。团队曾处理Web3财务、税务case数百件，金额累计数百亿。TaxDAO对在全球加速合规的大背景下如何进行税务筹划、架构搭建有独到、专业且契合客户需求的见解。安全、可信赖的服务体系：TaxDAO秉持客户至上的服务理念，有完善的内部风险控制和审计体系，确保客户信息安全。TaxDAO内部的前瞻研究院及时收集最新政策信息，确保您的税务方案始终符合监管要求。",
        caseDes: "咨询业务案例"
    },
    share: {
        twitter: 'twitter',
        telegram: 'telegram',
        wechat: '微信',
        weibo: '微博'
    },
    test: "测试",
    // 导航
    menuHome: "FinTax加密货币税务工具",
    menuServices: "咨询服务",
    menuContent: "内容",
    menuContentDepth: "深度",
    menuContentCompile: "编译",
    menuContentEvents: "评论和活动",
    menuAbout: "关于我们",
    menuAboutIntroduce: "TaxDAO简介",
    menuAboutJoin: "加入TaxDAO",
    menuAboutConsult: "咨询转载",
    menuAboutContact: "TaxDAO的优势",

    //footer
    copyRight: "Copyright ©2024 FinTax Technology Limited.",
    footerDes: "Privacy Policy ｜ Terms of use & legal notices",
};
